import React, { useState } from 'react';
import {
  PreloadedQuery,
  useMutation,
  usePreloadedQuery,
  useQueryLoader,
} from 'react-relay';
import { useLoaderData, useParams } from 'react-router-dom';
import { graphql } from 'relay-runtime';
import type {
  EmailQuery as EmailQueryType,
  EmailQuery$data as EmailQueryResponse,
} from './__generated__/EmailQuery.graphql';
import PageHeader, { PageHeaderGlimmer } from '../../tenant_portal/PageHeader';
import { TextFieldGlimmer } from '../../tenant_portal/components/Text';
import { PortalButtonGlimmer } from '../../components/PortalGlimmerElements';
import { ErrorType } from '../../components/Types';
import {
  camelCaseToTitle,
  formatErrorMessages,
  filterErrorsByPath,
} from '../../components/Tools';
import { FieldsDeepUpdate } from '../../typescript/types/FieldComponentProps';
import { updateDeep } from '../../components/fields/FieldTools';
import { EmailLoginActivateMutation$data } from './__generated__/EmailLoginActivateMutation.graphql';
import SubmitSpinner from '../../components/SubmitSpinner';
import Text from '../../tenant_portal/components/Text';
import PortalButton from '../../components/PortalButton';
import Alert from '../../components/Alert';

export const EmailQuery = graphql`
  query EmailQuery($key: String!) {
    loginActivation(key: $key) {
      ... on LoginActivation {
        email
      }
    }
  }
`;

export const LoginActivateMutation = graphql`
  mutation EmailLoginActivateMutation($input: LoginActivateInput!) {
    loginActivate(input: $input) {
      errors {
        path
        messages
      }
    }
  }
`;

const ActivateEmailPreloader = () => {
  const { activationKey } = useParams();
  const initialData = {
    email: '',
    password: '',
    passwordConfirmation: '',
  };
  const [redirecting, setRedirecting] = useState(false);
  const [emailLoginFields, setEmailLoginFields] = useState(initialData);
  const [errors, setErrors] = useState<ErrorType[]>([]);
  const [commitMutation, isMutationInFlight] = useMutation(
    LoginActivateMutation
  );

  const loaderQueryReference = useLoaderData() as PreloadedQuery<
    EmailQueryType,
    EmailQueryResponse
  >;
  const [queryReference] = useQueryLoader<EmailQueryType>(
    EmailQuery,
    loaderQueryReference
  );

  const data = usePreloadedQuery(
    EmailQuery,
    queryReference as PreloadedQuery<EmailQueryType, EmailQueryType>
  );

  if (!activationKey || !data.loginActivation) {
    return <div>Something went wrong</div>;
  }

  if (data.loginActivation.email && emailLoginFields.email === '') {
    setEmailLoginFields({
      email: data.loginActivation.email,
      password: '',
      passwordConfirmation: '',
    });
  }

  const generalErrors = filterErrorsByPath(
    errors,
    ['password', 'passwordConfirmation'],
    false
  );
  let formattedGeneralErrorMessages = '';
  generalErrors.forEach((error) => {
    const relPath = error.path.split('.').slice(-1)[0];
    const label = relPath === 'base' ? '' : camelCaseToTitle(relPath);
    formattedGeneralErrorMessages += formatErrorMessages([error], label);
  });

  const handleEmailLoginFieldsChange: FieldsDeepUpdate = (fieldName, value) => {
    setEmailLoginFields((prevFields) => {
      return updateDeep(prevFields, fieldName, value);
    });
  };

  const onEnterDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') handleSubmit();
  };

  const handleSubmit = () => {
    const blankErrors: ErrorType[] = [];
    Object.entries(emailLoginFields).forEach(([key, value]) => {
      if (value === '') {
        blankErrors.push({
          path: key,
          messages: ["can't be blank"],
        });
      }
    });

    if (blankErrors.length > 0) {
      setErrors(blankErrors);
      return;
    }

    commitMutation({
      variables: {
        input: {
          login: {
            email: emailLoginFields.email,
            password: emailLoginFields.password,
            passwordConfirmation: emailLoginFields.passwordConfirmation,
            activationKey: activationKey,
          },
        },
      },
      onCompleted: (response) => {
        const responseData = response as EmailLoginActivateMutation$data;
        if (!responseData || !responseData.loginActivate) {
          setErrors([
            {
              path: 'base',
              messages: [
                'There was an error activating your login. Please contact Property Matrix support for assistance.',
              ],
            },
          ]);
          setRedirecting(false);
          return;
        }
        if (
          responseData.loginActivate.errors &&
          responseData.loginActivate.errors.length > 0
        ) {
          setErrors(responseData.loginActivate.errors as ErrorType[]);
          setRedirecting(false);
          return;
        }
        window.location.href = '/signup_agreement';
      },
      onError: (error) => {
        setErrors([
          {
            path: 'base',
            messages: [
              'There was an error activating your login. Please contact Property Matrix support for assistance.',
            ],
          },
        ]);
        setRedirecting(false);
        console.error(error);
      },
    });
  };

  if (isMutationInFlight || redirecting) {
    if (!redirecting) setRedirecting(true);
    return (
      <SubmitSpinner spinnerText={'Activating account and logging in...'} />
    );
  }

  return (
    <>
      <PageHeader
        title={
          data.loginActivation.email ? 'Choose Password' : 'Create Account'
        }
      />
      {generalErrors.length > 0 && (
        <Alert severity="error">{formattedGeneralErrorMessages}</Alert>
      )}
      <Text
        field={{
          label: 'Email',
          name: 'email',
          type: 'email',
          value: emailLoginFields.email,
          disabled: data.loginActivation.email !== '',
        }}
        mode="edit"
        onChange={handleEmailLoginFieldsChange}
      />
      <Text
        field={{
          label: 'Password',
          name: 'password',
          value: emailLoginFields.password,
          type: 'password',
          autocomplete: 'new-password',
        }}
        mode="edit"
        onChange={handleEmailLoginFieldsChange}
        onKeyDown={onEnterDown}
        errors={filterErrorsByPath(errors, ['password'])}
      />
      <Text
        field={{
          label: 'Confirm Password',
          name: 'passwordConfirmation',
          value: emailLoginFields.passwordConfirmation,
          type: 'password',
          autocomplete: 'new-password-confirmation',
        }}
        mode="edit"
        onChange={handleEmailLoginFieldsChange}
        onKeyDown={onEnterDown}
        errors={filterErrorsByPath(errors, ['passwordConfirmation'])}
      />
      <PortalButton dataId="activate-button" onClick={handleSubmit}>
        Sign up
      </PortalButton>
    </>
  );
};

export const ActivateEmailGlimmer = () => {
  return (
    <>
      <PageHeaderGlimmer />
      <TextFieldGlimmer />
      <TextFieldGlimmer />
      <TextFieldGlimmer />
      <PortalButtonGlimmer />
    </>
  );
};

export default ActivateEmailPreloader;
